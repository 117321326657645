.portfolio{
    &_content{
        padding: 20px;
        width: 100%;

        &_filter{
            display: inline-block;
            margin: 0 auto;
            border-radius: 25px;
            // border: 1px dashed var(--yellow-theme-main-color);

            li{
                font-size: 16px;
                display: inline-block;
                cursor: pointer;
                position: relative;
                color: var(--yellow-theme-main-color);
                text-transform: uppercase;
                padding: 12px 20px;
                // padding-bottom: 0px;
                overflow: hidden;
                transition: all 0.5s ease;

                &.active{
                    background: var(--yellow-theme-main-color);
                    color: var(--yellow-theme-sub-text-color);
                    font-weight:600;
                    border-radius: 25px;
                }
            }
        }

        &_cards{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-auto-rows: auto;
            gap: 1 rem;
            margin-top: 10px;

            &_item{
                height: 366px;
                cursor: pointer;
                position: relative;
                &_img{
                    height: 100%;
                    a{
                             height: 100%;

                             img{
                                height: inherit;
                                object-fit: cover;
                                width: 100%;
                             }
                    }
                }
                
            }
        }
    }
}

  .portfolio_content_cards_item {
    position: relative;
    overflow: hidden;
  
    .portfolio_content_cards_item_img img {
      display: block;
      width: 100%;
      height: auto;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--yellow-theme-main-color);
      opacity: 0;
      transition: opacity 0.3s ease;
      
    
      
      .overlay_content {
        text-align: center;
        color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        
  
        p {
          margin-bottom: 10px;
          font-size: 18px;
          font-size: 4rem;
          color: black;
          position: relative;
          overflow: hidden;
          white-space: nowrap;
          animation: typingeffect 1s steps(30,end);

          
        }
  
        button {
          padding: 10px 20px;
          background-color: rgba(255, 255, 255, 0.8);
          border: 2px solid black;
          background: transparent;
          cursor: pointer;
          color: black;
        }
      }
    }
  
    &:hover .overlay {
      opacity: 1;
    
    }
  }

  @media only screen and (max-width:480px){
    .portfolio_content_filter > li{
        font-size: 11px;
    }
    .portfolio_content_cards{
        grid-template-columns: repeat(1,1fr);
    }
  }
  @keyframes typingeffect{
    from{
        width: 0;

    }
    to{
        width: 100%;
    }
  }