.skills_content{
    display: flex;
    padding: 20px;
    gap: 20px;
    border-bottom: none;
    &_inner{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items:stretch;
            &_category{
                display: inline-block;
                color: var(--yellow-theme-main-color);
                font-size: 22px;
                font-weight: 700;
                line-height: 1rem;
                letter-spacing: 0.5px;
                gap:4px;
                position: relative;
                text-transform: uppercase;
                padding: 15px 10px;
                border-bottom: 2px solid var(--yellow-theme-main-color);
                margin-bottom: 45px;

            }

            &_category::before,&_category::after{
                   position: absolute;
                   content: ' ';
                   width:2px;
                   height: 5px;
                   background-color: var(--yellow-theme-main-color);
                   bottom: -1px;
            }
            &_category::before{
                left:0;
            }
            &_category::after{
                right: 0;
            }
            div{
                width: 100%;
            }
    }
}
.progressbar{
    padding: 10px 0px 0px 0px;

    p{
        font-size: 1.6rem;
        margin: 0 0 ;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--yellow-theme-sub-text-color);
    }
}
@media only screen and (max-width:768px) {
    .skills{
        &_content{
            flex-direction: column;
        }
    }

    
}