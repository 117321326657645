.navbar{
    background-color: var( --yellow-theme-nav-background-color);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 1.2rem;

}
.navbar_contanier{
    display: flex;
    align-items: center;
    width: 100%;
    height: inherit;
    padding: 0px 20px;
    flex: 1;
}
.navbar_contanier_logo{
    color: var(--yellow-theme-main-color);
    cursor: pointer;
}
.navbar_contanier_menu{
    display: flex;
    list-style: none;
    text-align: center;

}
.nav-icon{
    display: none;
}
.navbar_contanier_menu_item{
    line-height: 40px;
    margin-right: 1.5rem;
    font-size: 1.6rem;
}
.navbar_contanier_menu_item::after{
    content: '';
    display: block;
    height:3px;
    width: 0;
    background: transparent;
    transition: all 0.5s ease;
}
.navbar_contanier_menu_item:hover::after{
    width: 100%;
    background:var(--yellow-theme-main-color);
}
.navbar_contanier_menu_item_links{
    color:var(--yellow-theme-main-color);
    text-decoration: none;
    padding: 0.5rem;

}
@media only screen and (max-width : 768px) {
    .navbar_contanier_menu{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 80px;
        left:-110%;
        opacity: 1;
        transition: all 0.5s ease;
        &.active{
            background: #181818;
            left: 0px;
            opacity: 1;
        }
        &_item{
            border-bottom: 1px solid #282828;
            margin: 0px;
            padding: 20px 0px;
        }
    }
    .nav-icon{
        display: block;
        cursor: pointer;
        color: var(--yellow-theme-main-color);
        padding-right: 20px;
    }
}