.about_content{
    display: flex;
    gap: 20px;
    padding: 20px;
    width: 100%;
}
.about_content_personal,.about_content_services{
   width: 50%;

}
.about_content_personal h3{
    margin-bottom: 10px;
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--yellow-theme-main-color);
    text-align: left;
}
h3.personal{
    padding-top: 20px;
    text-align: left;
}
p{
    color:var(--yellow-theme-sub-text-color);
    font-size: 1.8rem;
    line-height: 1.65;
    padding-bottom: 20px;
    border-bottom: 1px dashed var(--yellow-theme-main-color);
    text-align: left;
}
ul > li{
    line-height: 1 rem;
    margin-bottom: 15px;
    list-style-type: none;
    text-align: left;
    span.title,span.value{
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.5px;
        border-bottom: 2px solid var(--yellow-theme-main-color);
        position: relative;
        display: inline-block;
        margin-right: 16px;
        padding-bottom: 1px;
        color: var(--yellow-theme-sub-text-color);
       
    }
}
.about_content_services{
    justify-content: center;
    display: flex;
    align-items: center;
}
.about_content_services_inner{
    width: 350px;
    height: 350px;
    background: radial-gradient(180px, var(--yellow-theme-main-color),transparent 90%);
    position: relative;
    border-radius: 50%;
    border:1.5px solid var(--yellow-theme-main-color);
    animation:rotation 10s linear 1s infinite;


    div:nth-child(1){
        position: absolute;
        top: -9%;
        left: 41%;
    }
    div:nth-child(2){
        position: absolute;
        right: -8%;
        top:41.5%;
    }
    div:nth-child(3){
    position:absolute;
    bottom: -10%;
    left:41%;
    }
    div:nth-child(4){
        position: absolute;
        left: -8%;
        top:41%;
    }

}
.linkedin{
   margin-right: 90%;    
   font-size: 10px;
   display:inline-block;
   
}

@keyframes rotation{
    100%{
        transform: rotate(360deg);
    }
}

@media only screen and (max-width:768px){
    .about_content{
        flex-direction: column;

        &_personal,&services{
            width: 100%;
        }
    }
}