// .contact{
//     &_content{
//         padding: 20px;

//         &_header-text{
//             color: var(--yellow-theme-sub-text-color);
//             display: inline-block;
//             font-size: 22px;
//             font-weight: 600;
//             line-height: 1em;
//             letter-spacing: 0.5px;
//             position: relative;
//             text-transform: uppercase;
//             padding: 5px 10px;
//             border-bottom: 2px solid var(--yellow-theme-main-color);
//         }

//         &_header-text::before,
//         &_header-text::after{
//             position: absolute;
//             content: "";
//             width: 2px;
//             height: 5px;
//             background-color: var(--yellow-theme-main-color);
//             bottom: -1px;

//         }
//         &_header-text::before{
//             left: 0;
//         }
//         &_header-text::after{
//             right: 0;
//         }
//         &_form{
//             margin-top: 30px;

//             &_control{
//                 display: grid;
//                 grid-template-columns: repeat(2,1fr);
//                 gap: 40px;
//                 margin-bottom: 30px;

//                 div{
//                 position: relative;
//                 .inputMail,
//                 .inputName,
//                 .inputDescription{
//                     width: 100%;
//                     border: 2px solid var(--yellow-theme-main-color);
//                     padding: 16px 12px;
//                     transition: all 0.5 ease;
//                     position: relative;
//                     background-color: transparent;
//                     color: white;

//                 }
//                 .nameLabel,
//                 .emailLabel,
//                 .descriptionlabel{
//                     font-weight: 500;
//                     position: absolute;
//                     top: 7px;
//                     left: 12px;
//                     transition: all 0.2s ease;
//                     color: var(--yellow-theme-sub-text-color);
//                     font-size: 16px;    
//                 }
//                 .inputMail:focus,
//                 .inputName:focus,
//                 .inputDescription:focus{
//                     outline: none;
//                 }
//                 .inputName:focus + .nameLabel,
//                 .inputName:valid + .nameLabel,
//                 .inputMail:focus + .emailLabel,
//                 .inputMail:valid + .emailLabel,
//                 .inputDescription:focus + .descriptionlabel,
//                 .inputDescription:valid + .descriptionlabel{
//                     font-size: 18px;
//                     top:-25px;
//                     left: 0px;
//                 }

//                 }
//             }
//         }
//     }
// }
.contact_content {
    padding: 20px;
  
    .contact_content_header-text {
      font-size: 24px;
      margin-bottom: 20px;
      color: yellow;
    }
    .number{
        color: aliceblue;
    }
    .contact_info_list {
      list-style: none;
      padding: 0;
  
      li {
        font-size: 18px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
  
        a {
          text-decoration: none;
          color: white;
          margin-left: 10px;
  
          &:hover {
            text-decoration: underline;
          }
        }
  
        svg {
          margin-right: 10px;
        }
  
        span {
          margin-left: 10px;
        }
      }
    }
  }
  
  