
.home{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    

    &_text-wrapper{
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;

        h1{
            color: var(--yellow-theme-main-color);
            margin: 0px;
            font-weight:500 ;
            font-size: 6rem;
            line-height: 10rem;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            animation: typingEffectAnimation 2.5s steps(30,end);
            text-shadow: 2px 2px 4px rgba(104, 103, 103, 0.5);
        }

        &_contact_me{
            display: flex;
            justify-content: center;
            
        }
    }

}
@media only screen and (max-width:550px){
    .home_text-wrapper h1{
        font-size: 3rem;
        line-height: 5rem;
    }
}
@media only screen and (min-width:551px) and (max-width:768px){
    .home_text-wrapper h1{
        font-size: 4rem;
        line-height: 6rem;
    }
}
@media only screen  and (min-width:769px) and (max-width:990px){
    .home_text-wrapper h1{
        font-size: 6rem;
        line-height: 8rem;
    }
}

@keyframes typingEffectAnimation {
    from{
        width: 0;
    }
    to{
        width: 100%;
    }
    
}
button{
    background: transparent;
    cursor: pointer;
    padding: 10px 65px;
    font-size: 2rem;
    color:var(--yellow-theme-main-color);
    border:1.5px solid var(--yellow-theme-main-color);
    border-radius: 4px;
    font-weight: 500;

 }
 button:hover{
    background: var(--yellow-theme-main-color);
    color: black;
 }
 .Avatar{
  
    height: 50%;
    width: 50%;
    padding-left: 5%;
    margin-right: 1%;
    padding-top: 5%;
    
    filter: drop-shadow(5px 5px 20px #ffdd40);
 }